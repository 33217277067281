
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import DataGrid from '@/components/DataGrid.vue';
import { RpaOpsModule } from '@/store/om27.module';
import { UserPreferences } from '@/types/om27.types';
import TablePagination from '@/components/table/TablePagination.vue';
import UnassignedBotsGrid from '@/components/UnassignedBotsGrid.vue';

@Component({
  components: {
    Promised,
    Grid,
    GridCell,
    DataGrid,
    TablePagination,
    UnassignedBotsGrid,
  },
})
export default class Om27UnassignedBots extends Vue {

  get unassignedBots() {
    return RpaOpsModule.unassignedBots;
  }

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  promise: Promise<any> | null = null;
  created() {
    this.promise = RpaOpsModule.getUnassignedBots();
  }

}
